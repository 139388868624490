import {
  type FeaturedRestaurant,
  type Restaurant,
  RestaurantType,
} from "~/types/Restaurant";

export function createDummyRestaurant() {
  const restaurant: Restaurant = {
    id: "",
    type: RestaurantType.restaurant,
    attributes: {
      lowestAycePrice: "",
      lowestPpPrice: "",
      lowestXpPrice: "",
      lowestHsPrice: "",
      lowestBfpPrice: "",
      lowestSmPrice: "",
      lowestHahPrice: "",
      pricePerPerson: {
        amount: "",
        currency: "",
        symbol: "",
        format: "",
      },
      priceAndPricingType: {
        amount: 0,
        currency: "",
        symbol: "",
        format: "",
        pricingType: "",
      },
      isDeleted: false,
      lat: "",
      lng: "",
      largestTable: 0,
      branchId: 1,
      minPartySize: 0,
      name: "",
      names: { th: "Th Name", en: "En Name" },
      slug: "",
      favorited: false,
      allowBooking: false,
      availability: "",
      reviewsScore: 0,
      reviewsCount: 0,
      address: "",
      mapLocation: "",
      promotedByHh: false,
      enableBigGroupFeature: false,
      location: "",
      primaryLocation: { id: 0, name: "Primary Location" },
      cuisine: "",
      primaryCuisine: { id: 0, name: "Primary Cuisine" },
      imageCoverUrl: {
        thumb: "",
        large: "",
        square: "",
      },
      canonicalLink: "",
      lastBookingWasMade: "",
      totalCovers: 0,
      timeSlots: {},
      reservationSystemOnly: false,
      breadcrumbs: [],
      parking: false,
      corkageCharge: "",
      openingHours: "",
      foodDetails: "",
      daysInAdvance: 0,
      ambience: "",
      expiryDate: "",
      smallNote: "",
      acceptKids: false,
      selfPickupMessage: "",
      customText: "",
      bookingFlow: "",
      hasMultiplePricing: false,
      earnPoint: false,
      recordGuests: false,
      myMoobanVrLink: "",
      acceptVoucher: false,
      description: "",
      customSeats: [],
      tags: [],
      logoUrl: { medium: "", thumb: "" },
      platform: [],
      customSectionTitle: "",
      customSectionContent: "",
      availablePackageTypes: [],
      reservationDurationInHours: "",
      openingHoursShort: "",
      weekdayOpeningHours: {
        mon: "",
        tue: "",
        wed: "",
        thu: "",
        fri: "",
        sat: "",
        sun: "",
      },
      openingHoursV2: "",
      hashtags: [],
      videos: [],
      locations: [{ id: 0, title: "Location" }],
      cuisines: [{ id: 0, title: "Cuisine" }],
      jsonld: "",
      link: "",
      seo: null,
      linkToGroupLandingPage: "",
      gbPrimepayPublicKey: "",
      supportOrderNow: false,
      cookingTime: 0,
      hasDeliveryPricingTier: false,
      phone: "",
      phoneForDelivery: "",
      covid19Rating: {
        overallCleanliness: "4,9",
        socialDistancing: "0",
        staffProtection: "1",
      },
      priceSummaries: [
        {
          lowestPrice: "",
          highestPrice: "",
          packageType: "ayce",
          pricingType: "",
        },
      ],
      hasTicketGroups: false,
      tncImageUrl: "",
      restaurantBranch: [],
      isAds: false,
      position: 1,
    },
  };
  return restaurant;
}

export function createDummyFeaturedRestaurant() {
  const restaurant: FeaturedRestaurant = {
    id: "",
    type: RestaurantType.featuredResturant,
    attributes: {
      acceptVoucher: false,
      startDate: "",
      totalLocations: null,
      totalReviews: 0,
      avgReviews: 0,
      branchId: null,
      cuisine: "",
      location: null,
      rank: null,
      description: null,
      customText: null,
      name: "",
      names: {
        th: "",
        en: "",
      },
      totalCovers: 0,
      restaurantId: null,
      restaurantEncryptedId: null,
      link: "",
      cover: {
        thumb: "",
        slideThumb: "",
        square: "",
        original: "",
      },
      lastBookingWasMade: "",
      packageTypes: [],
      longPackageTypes: [],
      price: {
        amount: 0,
        currency: "",
        symbol: "",
        format: "",
      },
      priceV2: {
        amount: 0,
        currency: "",
        symbol: "",
        format: "",
      },
      pricingType: "",
      covid19Safety: false,
      earnPoint: false,
    },
  };
  return restaurant;
}
